import instance from './index';
import { useQuery, useMutation } from 'react-query';

//Branch API
const getBranchesByHospital = async (hospitalId) => await instance.get(`/branch/hospital/${hospitalId}`);

const addBranch = async (payload) => await instance.post('/branch', payload);

const editBranch = async ({payload, branchId}) => await instance.put(`/branch/${branchId}`, payload);

const getBranchesByIds = async (payload) => await instance.post(`/branch/ids`, payload);

const syncLocation = async (hospitalId) => await instance.get(`/integratebc/location/${hospitalId}`);

//Branch Queries
export function useGetBranchesByHospital(hospitalId) {
    return useQuery(['getBranches'], () => getBranchesByHospital(hospitalId), {enabled: !!hospitalId});
}

export function getBranchById(branchId) {
    return instance.get(`/branch/${branchId}`);
}

export function useBranchesByHospital(hospitalId) {
    return useQuery(['getAllBranches', hospitalId], () => getBranchesByHospital(hospitalId), 
        {enabled: !!hospitalId, cacheTime: 0});
}

export function useAddBranch() {
    return useMutation(addBranch);
}

export function useEditBranch() {
    return useMutation(editBranch);
}

export function useGetBranchesByIds() {
    return useMutation(getBranchesByIds);
}

export function useSyncLocation(hospitalId) {
    return useQuery(['syncLocation'], () => syncLocation(hospitalId), {enabled: !!hospitalId});
}
