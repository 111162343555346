import { notification } from 'antd';
import instance from './index';
import { useMutation, useQuery, useQueryClient } from 'react-query';

// Onboarding APIs
const getAllHospitals = async () => await instance.get(`/hospital`);
const addHospital = async () => await instance.post(`/hospital`);
const editHospital = async ({hospitalId, payload}) => await instance.put(`/hospital/${hospitalId}`, payload);

const getMainMenuByHospital = async (hospitalId) => await instance.get(`/mainMenu/hospital/${hospitalId}`);
const getCompleteMenu = async (hospitalId) => await instance.get(`/mainMenu/complete/${hospitalId}`);
const addMainMenu = async (payload) => await instance.post('/mainMenu', payload);
const editMainMenu = async ({payload, mainMenuId}) => await instance.put(`/mainMenu/${mainMenuId}`, payload);

const getBConfig = async () => await instance.get('/bcConfig');
const addBConfig = async (payload) => await instance.post('/bcConfig', payload);
const editBConfig = async ({payload, bcConfigId}) => await instance.put(`/bcConfig/${bcConfigId}`, payload);

const getBcEndpoint = async (bcConfigId) => await instance.get(`/bcEndpoint/bcConfig/${bcConfigId}`);
const addBcEndpoint = async (payload) => await instance.post('/bcEndpoint', payload);
const editBcEndpoint = async ({payload, bcEndpointId}) => await instance.put(`/bcEndpoint/${bcEndpointId}`, payload);

const getApiBaseUrl = async () => await instance.get('/apisetup');
const getBaseUrlByType = async (apiType) => await instance.get(`/apisetup/setup/${apiType}`);
const addApiBaseUrl = async (payload) => await instance.post('/apisetup', payload);
const editApiBaseUrl = async ({payload, apiSetupId}) => await instance.put(`/apisetup/${apiSetupId}`, payload);

const getApiParameter = async () => await instance.get('/apiparam');
const getParameterByApiSetup = async (apiSetupId) => await instance.get(`/apiparam/setup/${apiSetupId}`);
const addParameter = async (payload) => await instance.post('/apiparam', payload);
const editParameter = async ({payload, apiParameterId}) => await instance.put(`/apiparam/${apiParameterId}`, payload);

const getApiHospital = async (hospitalId) => await instance.get(`/apisetuphospital/hospital/${hospitalId}`);
const addApiHospital = async (payload) => await instance.post(`/apisetuphospital`, payload);
const editApiHospital = async ({payload, apiSetupHospitalId}) => await instance.put(`/apisetuphospital/${apiSetupHospitalId}`, payload);

//Onboarding Queries
export function useGetHospitals() {
    return useQuery(['getAllHospitals'], getAllHospitals);
}

export function useAddHospital() {
    return useMutation(addHospital);
}

export function useEditHospital() {
    return useMutation(editHospital);
}

export function useGetMainMenuByHospital(hospitalId) {
    return useQuery(['getMainMenuByHospital'], () => getMainMenuByHospital(hospitalId));
}

export function useGetCompleteMenu(hospitalId) {
    return useQuery(['getCompleteMenu', hospitalId], () => getCompleteMenu(hospitalId), {enabled: !!hospitalId});
}

export function useGetBConfig() {
    return useQuery(['getBConfig'], getBConfig);
}

export function useGetBcEndpoint(bcConfigId) {
    return useQuery(['getBcEndpoint'], () => getBcEndpoint(bcConfigId));
}

export function useGetApiBaseUrl() {
    return useQuery(['getApiBaseUrl'], getApiBaseUrl);
}

export function useGetBaseUrlByType(apiType) {
    return useQuery(['getBaseUrlByType', apiType], 
        () => getBaseUrlByType(apiType), 
        {enabled: !!apiType});
}

export function useGetApiParameter() {
    return useQuery(['getApiParameter'], getApiParameter);
}

export function useGetParameterByApiSetup(apiSetupId) {
    return useQuery(['getParameterByApiSetup', apiSetupId], 
        () => getParameterByApiSetup(apiSetupId), 
        {enabled: !!apiSetupId});
}

export function useGetApiHospital(hospitalId) {
    return useQuery(['getApiHospital', hospitalId], 
        () => getApiHospital(hospitalId), 
        {enabled: !!hospitalId});
}

//Onboarding Mutations
export function useAddBConfig() {
    const queryClient = useQueryClient();

    return useMutation(addBConfig, {
        onSuccess: () => {
            notification.info({message: `BC Config created successfully`});
            queryClient.invalidateQueries(`getBConfig`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useEditBConfig() {
    const queryClient = useQueryClient();

    return useMutation(editBConfig, {
        onSuccess: () => {
            notification.info({message: `BC Config update successfully`});
            queryClient.invalidateQueries(`getBConfig`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useAddBcEndpoint() {
    const queryClient = useQueryClient();

    return useMutation(addBcEndpoint, {
        onSuccess: () => {
            notification.info({message: `BC Endpoint created successfully`});
            queryClient.invalidateQueries(`getBcEndpoint`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useEditBcEndpoint() {
    const queryClient = useQueryClient();

    return useMutation(editBcEndpoint, {
        onSuccess: () => {
            notification.info({message: `BC Endpoint update successfully`});
            queryClient.invalidateQueries(`getBcEndpoint`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useAddMainMenu() {
    const queryClient = useQueryClient();

    return useMutation(addMainMenu, {
        onSuccess: () => {
            notification.info({message: `Main Menu created successfully`});
            queryClient.invalidateQueries(`getMainMenuByHospital`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useEditMainMenu() {
    const queryClient = useQueryClient();

    return useMutation(editMainMenu, {
        onSuccess: () => {
            notification.info({message: `Main Menu update successfully`});
            queryClient.invalidateQueries(`getMainMenuByHospital`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useAddApiBaseUrl() {
    const queryClient = useQueryClient();

    return useMutation(addApiBaseUrl, {
        onSuccess: () => {
            notification.info({message: `API Setup saved successfully`});
            queryClient.invalidateQueries(`getApiBaseUrl`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useEditApiBaseUrl() {
    const queryClient = useQueryClient();

    return useMutation(editApiBaseUrl, {
        onSuccess: () => {
            notification.info({message: `API Setup updated successfully`});
            queryClient.invalidateQueries(`getApiBaseUrl`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useAddParameter() {
    const queryClient = useQueryClient();

    return useMutation(addParameter, {
        onSuccess: () => {
            notification.info({message: `API Parameter saved successfully`});
            queryClient.invalidateQueries(`getParameterByApiSetup`);
            queryClient.invalidateQueries(`getApiParameter`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useEditParameter() {
    const queryClient = useQueryClient();

    return useMutation(editParameter, {
        onSuccess: () => {
            notification.info({message: `API Parameter updated successfully`});
            queryClient.invalidateQueries(`getParameterByApiSetup`);
            queryClient.invalidateQueries(`getApiParameter`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useAddApiHospital() {
    const queryClient = useQueryClient();

    return useMutation(addApiHospital, {
        onSuccess: () => {
            notification.info({message: `Hospital API saved successfully`});
            queryClient.invalidateQueries(`getApiHospital`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}

export function useEditApiHospital() {
    const queryClient = useQueryClient();

    return useMutation(editApiHospital, {
        onSuccess: () => {
            notification.info({message: `Hospital API updated successfully`});
            queryClient.invalidateQueries(`getApiHospital`);
        },
        onError: () => notification.info({message: `Error: ${queryClient.error.message}`})
    });
}
