import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useValidateLogin } from '../service/auth.service';
import { notification } from 'antd';
import _ from 'lodash';
// import { useNavigate } from 'react-router-dom';

const SelectedUserContext = createContext();

const safeJSONParse = (data, fallback) => {
    try {
        return JSON.parse(data);
    } catch (e) {
        return fallback;
    }
};

export const SelectedUserProvider = ({ children }) => {

    // const navigate = useNavigate();

    const [hospital, setHospital] = useState(JSON.parse(sessionStorage?.getItem('hmsv2-hospital') || null));
    const [loginRole, setLoginRole] = useState(JSON.parse(sessionStorage?.getItem('hmsv2-role') || null));
    const [loginUser, setLoginUser] = useState(JSON.parse(sessionStorage?.getItem('admin-user') || null));
    const [loginBranch, setLoginBranch] = useState(JSON.parse(sessionStorage?.getItem('hmsv2-branch') || null));
    const [loginFeatures, setLoginFeatures] = useState(JSON.parse(sessionStorage?.getItem('admin-feature') || null));
    const [userToken, setUserToken] = useState(sessionStorage.getItem('admin-token'));
    const [menuList, setMenuList] = useState({
        'mainMenus': JSON.parse(sessionStorage?.getItem('hmsv2-mainMenu')) || {},
        'menus': JSON.parse(sessionStorage?.getItem('hmsv2-menu')) || [],
        'submenus': JSON.parse(sessionStorage?.getItem('hmsv2-submenu')) || []
    });

    const [assignedBranches, setAssignedBranches] = useState(JSON.parse(sessionStorage?.getItem('hmsv2-branches') || null));

    const [menuItem, setMenuItem] = useState('');
    const [selectedUser, setSelectedUser] = useState(JSON.parse(sessionStorage.getItem('selectedUser') || null));
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState(JSON.parse(sessionStorage.getItem('selectedPatient') || null));
    const [selectedVisit, setSelectedVisit] = useState(JSON.parse(sessionStorage.getItem('selectedVisit') || null));
    const [selectedInsurance, setSelectedInsurance] = useState(safeJSONParse(sessionStorage.getItem('selectedInsurance') || null));
    const [selectedHospital, setSelectedHospital] = useState(JSON.parse(sessionStorage.getItem('selectedHospital') || null));
    const [selectedAcl, setSelectedAcl] = useState(JSON.parse(sessionStorage.getItem('selectedAcl') || null));

    const { data: loginUserData, mutate: validateLogin, isSuccess, isError, error } = useValidateLogin();

    const [ loginPayload, setLoginPayload ] = useState(null);

    useEffect(() => {

        if (isSuccess) {

            if (loginUserData?.data) {
                const loginData = loginUserData?.data;

                if (['ACTIVE', 'NEW'].includes(loginData.user.userStatus)) {
                    notification.success({ "message": "Successfully logged in" });

                    sessionStorage.setItem('hmsv2-hospital', JSON.stringify(loginData.hospital));
                    sessionStorage.setItem('hmsv2-role', JSON.stringify(loginData.role));
                    sessionStorage.setItem('hmsv2-branches', JSON.stringify(loginData.branches));
                    sessionStorage.setItem('admin-user', JSON.stringify(loginData.user));
                    sessionStorage.setItem('admin-feature', JSON.stringify(loginData.accessControlList));
                    sessionStorage.setItem('admin-token', loginData.token);

                    sessionStorage.setItem('hospitalId', loginData.hospital.hospitalId);
                    sessionStorage.setItem('admin-name', `${loginData.user.firstName} ${loginData.user.lastName}`);
                    sessionStorage.setItem('admin-username', loginData.user.username);
                    sessionStorage.setItem('admin-role', loginData.role.roleName);
                    sessionStorage.setItem('userId', loginData.user.userId);

                    sessionStorage.setItem('hmsv2-mainMenu', JSON.stringify(_.groupBy(loginData.mainMenu, 'orderId')));
                    sessionStorage.setItem('hmsv2-menu', JSON.stringify(loginData.menus));
                    sessionStorage.setItem('hmsv2-submenu', JSON.stringify(loginData.submenus));

                    setHospital(loginData.hospital);
                    setLoginRole(loginData.role);
                    setLoginUser(loginData.user);
                    setAssignedBranches(loginData.branches);
                    setUserToken(loginData.token);
                    setMenuList({
                        'mainMenu': _.groupBy(loginData.mainMenu, 'orderId'),
                        'menus': loginData.menus,
                        'submenus': loginData.submenus
                    });

                    // window.location.reload();
                } else {
                    notification.warning({ "message": "Cannot login, user not active..." })
                }
            } else {
                notification.error({ 'message': 'Server offline...' });
                return;
            }

        }else if(loginPayload){

            setLoginPayload(null);

            notification.error({ 'message': 'Login failed, 3 unsuccessful attempt will block your access, check credentials and try again...' });
            return;
        }

        if (isError) {
            notification.error({ 'message': error || 'An error occurred' });
        }
    }, [isSuccess, isError]);

    const handleLoginUser = (loginPayload) => {

        setLoginPayload(loginPayload);

        validateLogin({
            email: loginPayload.email,
            password: loginPayload.password
        });
    };

    const findSubmenuList = (data, mainMenuId, menuId) => {
        for (const key in data) {
            const entries = data[key];
            for (const entry of entries) {
                if (entry.mainMenuId === mainMenuId && entry.menuId === menuId) {
                    return entry.submenuList;
                }
            }
        }

        return null; // Return null if not found
    };

    const handleLogoutUser = () => {
        setLoginUser(null);
        setUserToken(null);
        setLoginFeatures(null);
        setLoginBranch(null);
        setMenuList(null);

        sessionStorage.removeItem('admin-user');
        sessionStorage.removeItem('hmsv2-branch');
        sessionStorage.removeItem('admin-token');
        sessionStorage.removeItem('admin-feature');

        sessionStorage.removeItem('hmsv2-mainMenu');
        sessionStorage.removeItem('hmsv2-menu');
        sessionStorage.removeItem('hmsv2-submenu');

        window.location.replace(`/`);
    }

    return (
        <SelectedUserContext.Provider value={{
            menuItem, setMenuItem, selectedUser, setSelectedUser, setHospital,
            loginUser, userToken, hospital, loginRole, selectedInsurance, setSelectedInsurance,
            menuList, handleLoginUser, handleLogoutUser, loginFeatures, setMenuList,
            selectedPatient, setSelectedPatient, selectedRole, setSelectedRole,
            assignedBranches, loginBranch, setLoginBranch, selectedVisit, setSelectedVisit,
            selectedHospital, setSelectedHospital, selectedAcl, setSelectedAcl, findSubmenuList
        }}>
            {children}
        </SelectedUserContext.Provider>
    );
};

SelectedUserProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useSelectedUser = () => {
    return useContext(SelectedUserContext);
}
